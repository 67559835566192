import React, { useState } from 'react';
import { Tooltip, Typography } from 'antd';
const { Title } = Typography;

interface PropsType {
  candidate: any;
}

const BasicInfo: React.FC<PropsType> = ({ candidate }) => {
  return (
    <>
      <div
        style={{
          marginLeft: '-38px',
          wordWrap: 'break-word',
          marginTop: 10,
        }}
      >
        <span
          style={{
            marginTop: 0,
            fontSize: 18,
            fontWeight: 600,
            textAlign: 'start',
            color: '#012653',
          }}
        >
          <span>{candidate?.firstName}</span> <span>{candidate?.lastName}</span>
        </span>
        <div
          style={{
            marginTop: 0,
            fontSize: 15,
            fontWeight: 600,
            textAlign: 'start',
            color: '#000000d9',
            paddingRight: 10,
          }}
        >
          <>{candidate?.position && candidate?.position}</>
        </div>
        <Title
          level={5}
          style={{
            textAlign: 'start',
            color: '#000000D9',
            margin: 0,
            marginTop: -3,
            paddingRight: 10,
          }}
        >
          <span
            style={{
              fontWeight: 400,
              color: '#000000',
              fontSize: 10,
              textAlign: 'start',
            }}
          >
            {candidate?.company && (
              <span
                style={{ color: '#000000D9', fontSize: 15, fontWeight: 700 }}
              >
                @{candidate?.company}
              </span>
            )}{' '}
            {candidate?.currentLocation?.city &&
            candidate?.currentLocation?.country
              ? '| ' +
                candidate?.currentLocation?.city +
                ', ' +
                candidate?.currentLocation?.country
              : (candidate?.currentLocation?.city == null ||
                  candidate?.currentLocation?.city == undefined ||
                  candidate?.currentLocation?.city == '') &&
                candidate?.currentLocation?.country
              ? '| ' + candidate?.currentLocation?.country
              : ''}
          </span>
        </Title>
      </div>
    </>
  );
};

export default BasicInfo;
