import { EducationIcon } from 'assets/icons/DetailedProfile';
import React from 'react';
import SectionTitle from './SectionTitle';
import SingleEducation from './SingleEducation';
import EducationComment from './EducationComment';

interface PropsType {
  candidate: any;
  edu: any;
  showTitle?: any;
}

const Styles: any = {
  root: {
    padding: '10px 12px 4px',
    // paddingLeft: '12px',
    // paddingRight: '12px',
    boxShadow: '0px 0px 4px #00000040',
    borderRadius: 10,
    // paddingBottom: 4,
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    gap: '4px',
  },
};

// Function to merge and sort education entries
function mergeAndSortEducation(educations: any[]) {
  const mergedEducations = [...educations]; // Clone the educations array to avoid mutation

  for (let i = 0; i < mergedEducations.length; i++) {
    for (let j = i + 1; j < mergedEducations.length; j++) {
      const schoolNameI = mergedEducations[i]?.schoolName?.toLowerCase();
      const schoolNameJ = mergedEducations[j]?.schoolName?.toLowerCase();

      if (schoolNameI === schoolNameJ) {
        if (!mergedEducations[i].mergedData) {
          mergedEducations[i].mergedData = [mergedEducations[i]];
        }

        mergedEducations[i].mergedData.push(mergedEducations[j]);
        mergedEducations.splice(j, 1); // Remove the duplicate entry
        j--; // Adjust the index after removing an element
      }
    }

    // Sort mergedData by startYear and endYear for each education
    if (mergedEducations[i]?.mergedData) {
      mergedEducations[i].mergedData.sort((a: any, b: any) => {
        if (a.startYear !== b.startYear) {
          return b.startYear - a.startYear; // Sort by descending startYear
        }
        return b.endYear - a.endYear; // Then sort by descending endYear
      });
    }
  }

  return mergedEducations;
}

const Education: React.FC<PropsType> = ({
  candidate,
  edu,
  showTitle = true,
}) => {
  const column1: any = [];
  const column2: any = [];
  const processedEducation = mergeAndSortEducation(edu);

  processedEducation.forEach((education: any, index: any) => {
    const card = (
      <div
        key={`${index}-education`}
        style={{
          marginBottom: '3px',
          marginTop: '3px',
          width: '100%',
          maxWidth: '320px',
        }}
      >
        <SingleEducation
          index={index}
          education={education}
          candidateID={candidate?._id}
          comments={candidate?.educationComment}
        />
        {education?.comments && (
          <EducationComment comment={candidate?.educationComment} />
        )}
      </div>
    );

    // if (index < edu?.length / 2) {
    column1.push(card);
    // } else {
    // column2.push(card);
    // }
  });

  return processedEducation?.length > 0 ? (
    <div style={Styles.root}>
      {showTitle && (
        <SectionTitle
          title="Education"
          icon={EducationIcon}
          fontSize="13px"
          fontWeight="600"
          actionType="add"
          actionText="Add education"
        />
      )}
      <div style={{ display: 'flex', gap: '8px' }}>
        <div style={Styles.column}>{column1}</div>
        {/* <div style={Styles.column}>{column2}</div> */}
      </div>
    </div>
  ) : null;
};

export default Education;
