import React, { useContext } from 'react';
import { Typography } from 'antd';

interface PropsType {
  title?: any;
  fontSize?: any;
  icon?: any;
  actionType?: any;
  //   actionHandler?: any;
  actionText?: any;
  fileLink?: any;
  deleteAction?: any;
  updateAction?: any;
  fontWeight?: any;
  lastEndIcon?: any;
  candidateId?: string;
}

const Styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '-6px',
    padding: '4px 0px',
    marginTop: '-5px',
  },
  iconStyle: { marginTop: '-1px' },
  titleStyle: {
    fontWeight: '500',
    marginLeft: 5,
    color: '#012653',
    marginTop: '-1px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  actionWrapper: {},
};

const SectionTitle: React.FC<PropsType> = ({
  title,
  fontSize,
  icon,
  fontWeight,
  lastEndIcon,
}) => {
  return (
    <div style={Styles.root}>
      <div style={Styles.flex}>
        <img src={icon} style={Styles.iconStyle} />
        <Typography
          style={
            fontWeight
              ? {
                  ...Styles.titleStyle,
                  fontSize: 18,
                  fontWeight: fontWeight,
                  marginLeft: title == 'summary' ? '0px' : '5px',
                }
              : { ...Styles.titleStyle, fontSize: 18 }
          }
        >
          {title}
        </Typography>
        <img src={lastEndIcon} style={{ ...Styles.iconStyle, marginLeft: 5 }} />
      </div>
    </div>
  );
};

export default SectionTitle;
