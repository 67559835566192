import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import AvatarPlaceholder from '../../../../../../assets/icons/DetailedProfile/educationPlaceholder.svg';
import _ from 'lodash';

interface PropsType {
  index: any;
  education: any;
  onEdit?: (id: any) => any;
  comments: string;
  candidateID: string;
}

const Styles = {
  root: {
    paddingLeft: 10,
    display: 'inline-block',
    maxWidth: 400,
    flex: '85%',
    marginTop: 10,
    marginBottom: '-25px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 26,
    marginTop: '-8px',
  },
  year: {
    fontStyle: 'italic',
    fontSize: 10,
    fontWeight: 500,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  position: {
    fontWeight: 400,
    fontSize: 15,
    marginTop: 0,
    marginBottom: 0,
    color: '#000000d9',
  },
  company: {
    fontWeight: 600,
    fontSize: 15,
    marginTop: 0,
    marginBottom: 0,
    color: '#000000d9',
  },
  totalYears: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'rgba(0,0,0,.5)',
    marginTop: '-1px',
  },
  location: {
    fontWeight: 400,
    fontSize: 10,
    marginTop: 0,
    marginBottom: 0,
    color: '#495057',
  },
  avatar: {
    backgroundColor: '#F5F7F9',
  },
  placeholderAvatar: {
    backgroundColor: '#ccc',
    marginRight: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const SingleEducation: React.FC<PropsType> = ({ education }) => {
  const [imgError, setImgError] = useState(false);

  const handleImageError = () => {
    setImgError(true);
    return false;
  };
  if (!education?.mergedData) {
    education.mergedData = [education];
  }
  if (education?.mergedData) {
    education.mergedData = education.mergedData.sort((s: any) =>
      s?.current ? -1 : 1
    );
  }

  if (education.current) {
    education.endYear = 'Present';
  }

  useEffect(() => {
    if (
      education?.logo == null ||
      education?.logo == undefined ||
      education?.logo == ''
    ) {
      setImgError(true);
    }
  }, [education?.logo]);
  education = _.cloneDeep(education);

  return (
    <>
      {education?.mergedData?.map((education: any, i: any) => {
        if (i > 0) {
          return null;
        }
        return (
          <div
            key={i}
            style={{
              ...Styles.wrapper,
              flexDirection: 'row',
              alignItems: 'end',
            }}
          >
            <div style={{ ...Styles.root, wordWrap: 'break-word' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  gap: '16px',
                }}
              >
                {!imgError ? (
                  <Avatar
                    style={{
                      ...Styles.avatar,
                      backgroundImage: `url(${education?.logo})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      objectFit: 'cover',
                      flexShrink: 0,
                    }}
                    size={28}
                    src={education?.logo}
                    onError={handleImageError}
                  />
                ) : (
                  <span
                    style={{
                      ...Styles.placeholderAvatar,
                      flexShrink: 0,
                      height: '28px',
                      width: '28px',
                      margin: '5px',
                      backgroundColor: '#f9f6f7',
                      borderRadius: '50%',
                    }}
                  >
                    <img
                      style={{
                        ...Styles.placeholderAvatar,
                        height: '18px',
                        width: '18px',
                        flexShrink: 0,
                        background: '#f9f6f7',
                        margin: '5px',
                        marginTop: '2px',
                      }}
                      src={AvatarPlaceholder}
                      alt="placeholder"
                    />
                  </span>
                )}
                <div style={{ marginLeft: '-6px' }}>
                  <p style={Styles.company}>{education?.schoolName}</p>
                  {
                    <>
                      <span
                        style={{
                          color: 'rgba(0,0,0,0.5)',
                          fontSize: '12px',
                          fontWeight: '400',
                        }}
                      >
                        {education.startYear} {education.endYear && '-'}{' '}
                        {education.endYear}
                      </span>
                      <p style={Styles.position}>{education?.degree}</p>
                      <div className="">
                        <span
                          style={{
                            color: '#424952',
                            fontSize: '12px',
                            fontWeight: '400',
                          }}
                        >
                          {education?.location?.city &&
                          education?.location?.country
                            ? `${education?.location?.city}, ${education?.location?.country}`
                            : education?.location?.city &&
                              !education?.location?.country
                            ? `${education?.location?.city}`
                            : ''}
                        </span>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>

            <div style={{ flex: 1 }} />
          </div>
        );
      })}

      {education?.mergedData?.length > 1
        ? education?.mergedData?.map((d: any, i: any) => {
            if (i == 0) {
              return null;
            }
            return (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  marginTop: '12px',
                  marginLeft: '66px',
                  marginBottom: '10px',
                }}
                key={i}
              >
                <div style={{ marginTop: '10px' }}>
                  <div
                    style={{
                      height: '11px',
                      width: '11px',
                      backgroundColor: '#418394',
                      borderRadius: '100%',
                    }}
                  />
                  <div
                    style={{
                      height: '36px',
                      width: '1.2px',
                      backgroundColor: '#418394',
                      marginTop: '-4px',
                      marginBottom: '-4px',
                      marginLeft: '4.6px',
                    }}
                  />
                  <div
                    style={{
                      height: '11px',
                      width: '11px',
                      backgroundColor: '#418394',
                      borderRadius: '100%',
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                    marginTop: '1px',
                    marginLeft: '16px',
                  }}
                >
                  <span
                    style={{
                      color: 'rgba(0,0,0,0.5)',
                      fontSize: '14px',
                      fontWeight: '400',
                    }}
                  >
                    {d.startYear} - {d.endYear}
                  </span>
                  <div style={Styles.position}>{d?.degree}</div>
                  <div className="">
                    <span
                      style={{
                        color: '#424952',
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                    >
                      {d?.location?.city && d?.location?.country
                        ? `${d?.location?.city}, ${d?.location?.country}`
                        : d?.location?.city && !d?.location?.country
                        ? `${d?.location?.city}`
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        : ''}
    </>
  );
};

export default SingleEducation;
